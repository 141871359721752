import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private toastr: MessageService) {}

  public showSuccessMessage(message: string, title: string = '') {
    this.toastr.add({
      severity: 'success',
      summary: title,
      detail: message,
    });
  }

  public showInfoMessage(message: string, title: string = '') {
    this.toastr.add({
      severity: 'info',
      summary: title,
      detail: message,
    });
  }

  public showErrorMessage(message: string, title: string = '') {
    this.toastr.add({
      severity: 'error',
      summary: title,
      detail: message,
    });
  }

  public showWarningMessage(message: string, title: string = '') {
    this.toastr.add({
      severity: 'warning',
      summary: title,
      detail: message,
    });
  }

  public removeAllNotifications(): void {
    this.toastr.clear();
  }
}
