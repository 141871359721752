import { default as data } from '../../package.json';

export const environment = {
  appVersion: data.version,
  production: false,
  apiUrl: 'https://api.kidsgate.org',
  authDomain: 'login-kidgates-dev01.us.auth0.com',
  authAudience: 'https://api-dev.kidgates.co',
  authClientId: 'VdmVwyJJT3I2kxuIkMDUuVtZqWf91pOy',
  authScope: 'openid profile create read update delete',
};
